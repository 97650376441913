/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500;700&display=swap"); */
/* font-family: 'Hanken Grotesk', sans-serif; */

/* @import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:wght@400;500;700&display=swap'); */
/* font-family: 'Schibsted Grotesk', sans-serif; */

/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap'); */
/* font-family: 'Space Grotesk', sans-serif; */

/* Fonts with their fontWeight
 Roboto  font-weight
 Thin -  100
 Light - 300
 Regular - 400 -> poppins_regular
 Medium - 500  -> poppins_medium
 Bold - 700    -> poppins_bold
 */

/* 
You can use numeric values for `font-weight`. They all correspond to a particular named weight:
100 Thin
200 Extra Light
300 Light
400 Normal
500 Medium
600 Semi Bold
700 Bold
800 Extra Bold
900 Ultra Bold */

/* Text sizes */
/*  mainHeading: 48px: --heading: 36px; --subHeading: 24px; --paragraph: 16px; */

/* 
font-family: 'Poppins', sans-serif; 
font-family: Montserrat, sans-serif;*/

/* 
 * NOTE: FONTS & COLORS ARE USED FROM UIKIT.CSS FILE FROM PUBLIC DIRECTORY because from their h1-h6, p are being used.
 * NOTE-2: `poppins font` implemented on uikit.css not working but here working.
 */
:root {
  --color--primary: #ff7a5c;
  --color--accent: #f2d123;
  /* HEADING & TEXT colors & fonts*/
  --color--heading: #222;
  --font--heading: "Poppins", sans-serif;
  --color--text: #666;
  --font--text: "Poppins", sans-serif;
  --color--link: #146eb4;
  --color-border-grey: #e4e7ed;
  --color-body-background: #f0f0f0;
  --color-white: #fff;
  /* --color--text-light: rgba(33, 33, 33, 0.7); */
  /* --color--text-lighter: rgba(33, 33, 33, 0.5); */
  /* --color--text-lightest: rgba(33, 33, 33, 0.2); */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  /* UNSORTED */
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --lightShadowNew: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadowNew: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --mainBorderRadius: 0.25rem;
  --formBackground: #cfe2f0;

  --imageBackgroundColor: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  /* --imageBackgroundColor: #0f294d80; */
  /* --imageBackgroundColor: #81a6ec;; */

  /* possible values: '#27e0b4, #3a6073 */
  --navLinkHoverColor: #ff7a5c;
  --linkHoverColor: #ff7a5c;

  --max-width: 1170px;
  --fixed-width: 620px;
  --viewport-width-80: 80vw;
  --viewport-width-75: 75vw;

  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
}
.GREY-N-PRIMARY {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(185, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(185, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);

  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: #f1f5f8;
}

html {
  /* font-family: Arial, Helvetica, sans-serif; */
  /* color: var(--color--text); */
  font-weight: 300;
  line-height: 1.5rem;
  font-family: var(--font--text);
  word-wrap: ju;
}
p,
li {
  color: var(--color--text);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  font-family: var(--font--text);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color--heading);
  /* font-weight: 300; */

  /* line-height: 1.25;
  font-weight: 500;
  letter-spacing: var(--spacing); */
}
.title-style {
  color: var(--color--heading);
  font-weight: 300;
  font-family: var(--font--heading);
}

.tm-heading-fragment > a {
  color: inherit;
  text-decoration: none;
}

/* Global styles */
.tb-icon-grey {
  /* grey icon */
  color: #666;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}
.tb-icon-grey:hover {
  color: #575656;
}
.tb-icon {
  color: #ff7a5c;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}
.tb-icon:hover {
  color: #ee340a;
}
.tb-py-small {
  padding-top: 0.5rem;
  padding-bottom: 0, 5rem;
}
.remove-underline {
  text-decoration: none;
}
.text-eql-aligned {
  text-align: justify;
}
.bookmark-icon {
  color: var(--color--primary);
  /* color: #338280; */
  /* color: #dadfe6; */
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.bookmark-icon:hover {
  width: 1.5rem;
  height: 1.5rem;
  /* color: var(--color--primary); */
}

.error-image {
  width: 35rem;
  height: 25rem;
  object-fit: contain;
  /* border: 1px solid red; */
}

/* https://stackoverflow.com/a/63894796/9716479 
  ISSUE: problem when content is small doesn't take whole space*/
/* html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
} */

/* https://stackoverflow.com/a/73419966/9716479 */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container main {
  flex-grow: 1;
}

/* https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing */
/* TODO: COMMENT iframe code in PRODUCTION*/
/* iframe {
  display: none;
} */
